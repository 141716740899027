import * as React from 'react';
import { PersistedStartFeedCardModel } from '../models/persisted-start-feed-card-model';
import {
  PicColor,
  PicContainer,
  PicCountryTag,
  PicDivider,
  PicFontSize,
  PicFontWeight,
  PicIconButton,
  PicIconName,
  PicImage,
  PicTag,
  PicText,
  PicToggle,
} from '@cardinalblue/pic-collage-cms-ui';
import { dispatchPersistedStartFeedCardModel } from '../models/utils';
import { StartFeedCardTypeEnum } from '../models/start-feed-card-type-enum';
import { TemplateTagModel } from '../../common/models/template-tag-model';

type Props = {
  order: number;
  collapsed: boolean;
  active: boolean;
  onClick?: () => void;
  onInspectClick: () => void;
  onEditClick: () => void;
  onCloneClick: () => void;
  onDeleteClick?: () => void;
  hidePinning?: boolean;
  startFeedCard: PersistedStartFeedCardModel;
  templateTags: TemplateTagModel[];
};

export const StartFeedCardCard: React.FC<Props> = ({
  order,
  collapsed,
  active,
  onClick,
  onInspectClick,
  onEditClick,
  onCloneClick,
  onDeleteClick,
  hidePinning,
  startFeedCard,
  templateTags,
}) => {
  const borderColor = active ? PicColor.Teal700 : PicColor.Grey300;
  const borderThickness = active ? '1px' : '1px';
  const backgroundColor = active ? PicColor.Teal300 : PicColor.White;
  const fontColor = active ? PicColor.Teal700 : undefined;

  const isCountriesExclusive = startFeedCard.getIsCountriesExclusive();
  const countries = startFeedCard.getCountries();

  const getTemplateTagName = React.useCallback(
    (templateTagId: number | null) => {
      if (!templateTagId) return '';

      return templateTags.find((t) => t.getId() === templateTagId)?.getName() || '';
    },
    [templateTags],
  );

  return (
    <PicContainer borderRadius="8px" background={backgroundColor}>
      <div
        style={{
          padding: '12px 0',
          display: 'flex',
          flexFlow: 'row',
          cursor: onClick ? 'pointer' : undefined,
        }}
        onClick={onClick}
      >
        <div
          style={{
            borderRight: `${borderThickness} solid ${borderColor}`,
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PicText color={fontColor} weight={PicFontWeight.Bold}>
            {order}
          </PicText>
        </div>
        <div style={{ flexGrow: '1', padding: '0 12px', borderRight: `${borderThickness} solid ${borderColor}` }}>
          <div style={{ height: '100%', display: collapsed ? 'flex' : 'none', alignItems: 'center', gap: '4px' }}>
            <PicTag color={PicColor.Teal500} copy={startFeedCard.getType()} />
            <PicText color={fontColor} weight={PicFontWeight.Medium}>
              {startFeedCard.getAnalyticId()}
            </PicText>
          </div>
          <div style={{ display: collapsed ? 'none' : undefined }}>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
              }}
            >
              <div
                style={{
                  flexBasis: '50%',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '8px',
                }}
              >
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Id
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getId()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Type
                  </PicText>
                  <div>
                    <PicTag color={PicColor.Teal500} copy={startFeedCard.getType()} />
                  </div>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Analytic Id
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getAnalyticId()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Title
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getTitle()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Subtitle
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getSubtitle() || 'None'}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Label
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getLabel() || 'None'}
                  </PicText>
                </div>
              </div>
              <div
                style={{
                  flexBasis: '50%',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '4px',
                }}
              >
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Start At
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getStartAt()?.toDateString(-8) || 'None'}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    End At
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getEndAt()?.toDateString(-8) || 'None'}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Countries
                  </PicText>
                  <div style={{ display: 'flex', gap: '2px', flexWrap: 'wrap', alignItems: 'center' }}>
                    <PicText
                      size={PicFontSize.Sm}
                      weight={PicFontWeight.Medium}
                      color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}
                    >
                      {startFeedCard.getIsCountriesExclusive() ? 'Excludes' : 'Includes'}
                    </PicText>
                    {countries.map((c) => (
                      <PicCountryTag isoCode={c} color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500} />
                    ))}
                    {countries.length === 0 && (
                      <PicText
                        size={PicFontSize.Sm}
                        weight={PicFontWeight.Medium}
                        color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}
                      >
                        No Countries
                      </PicText>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Target Vip/Free Users?
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getTargetVipFreeUserCopy()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Target New/Old Users?
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getTargetNewOldUserCopy()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Minimal Required Version for iOS
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getTargetMinimalRequiredIosAppVersion().toString()}
                  </PicText>
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                  <PicText color={fontColor} size={PicFontSize.Sm}>
                    Minimal Required Version for Android
                  </PicText>
                  <PicText color={fontColor} weight={PicFontWeight.Medium}>
                    {startFeedCard.getTargetMinimalRequiredAndroidAppVersion().toString()}
                  </PicText>
                </div>
              </div>
            </div>
            <PicDivider marginTop="8px" marginBottom="8px" />
            {dispatchPersistedStartFeedCardModel(startFeedCard, {
              [StartFeedCardTypeEnum.Link]: (linkCard) => (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      flexBasis: '50%',
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '4px',
                    }}
                  >
                    <PicText color={fontColor} size={PicFontSize.Sm}>
                      Link
                    </PicText>
                    <PicText color={fontColor} weight={PicFontWeight.Medium}>
                      {linkCard.getLink()}
                    </PicText>
                  </div>
                  <div
                    style={{
                      flexBasis: '50%',
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '4px',
                    }}
                  >
                    <PicText color={fontColor} size={PicFontSize.Sm}>
                      Image
                    </PicText>
                    <PicImage src={linkCard.getImageUrl()} width="100px" />
                  </div>
                </div>
              ),
              [StartFeedCardTypeEnum.TemplateCategory]: (templateCategoryCard) => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {templateCategoryCard.getChips().map((chip, chipIndex) => (
                    <div
                      key={chipIndex}
                      style={{
                        flexBasis: '50%',
                        display: 'flex',
                        flexFlow: 'column',
                        gap: '4px',
                      }}
                    >
                      {chipIndex >= 2 && (
                        <div style={{ width: '50px' }}>
                          <PicDivider marginTop="16px" marginBottom="16px" />
                        </div>
                      )}
                      <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                        <PicText
                          color={fontColor}
                          size={PicFontSize.Sm}
                          weight={PicFontWeight.Bold}
                        >{`#${chipIndex + 1} Chip`}</PicText>
                      </div>
                      <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                        <PicText color={fontColor} size={PicFontSize.Sm}>
                          Name
                        </PicText>
                        <PicText color={fontColor} weight={PicFontWeight.Medium}>
                          {chip.getName()}
                        </PicText>
                      </div>
                      <div style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
                        <PicText color={fontColor} size={PicFontSize.Sm}>
                          Template Tag
                        </PicText>
                        <PicText color={fontColor} weight={PicFontWeight.Medium}>
                          {getTemplateTagName(chip.getTemplateTagId())}
                        </PicText>
                      </div>
                    </div>
                  ))}
                </div>
              ),
            })}
          </div>
          <div></div>
        </div>
        {!hidePinning && (
          <div
            style={{
              borderRight: `${borderThickness} solid ${borderColor}`,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexFlow: 'column',
              gap: '4px',
            }}
          >
            <PicToggle value={startFeedCard.getIsPinned()} disabled={!active} />
          </div>
        )}
        <div style={{ padding: '8px', display: 'flex', gap: '4px', alignItems: 'center' }}>
          <PicIconButton size="large" iconName={PicIconName.Search} onClick={onInspectClick} />
          <PicIconButton size="large" iconName={PicIconName.Edit} onClick={onEditClick} />
          <PicIconButton size="large" iconName={PicIconName.Duplicate} onClick={onCloneClick} />
          {onDeleteClick && <PicIconButton size="large" iconName={PicIconName.Delete} onClick={onDeleteClick} />}
        </div>
      </div>
    </PicContainer>
  );
};
