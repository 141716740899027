import { AppVersionVo } from '../../common/models/app-version-vo';
import { CountryModel } from '../../common/models/country-model';
import { DateVo } from '../../common/models/date-vo';
import { PersistedLinkStartFeedCardModel } from '../models/link-start-feed-cards/persisted-link-start-feed-card-model';
import { PersistedStartFeedCardModel } from '../models/persisted-start-feed-card-model';
import { StartFeedCardTypeEnum } from '../models/start-feed-card-type-enum';
import { PersistedTemplateCategoryStartFeedCardModel } from '../models/template-category-start-feed-cards/persisted-template-category-start-feed-card-model';
import { PersistedTemplateCategoryStartFeedCardChipModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-chips/persisted-template-category-start-feed-card-chip-model';

type BaseStartFeedCardDto = {
  id: number;
  analytic_id: string;
  payload_type: StartFeedCardTypeEnum;
  payload_id: number;
  title: string;
  subtitle: string;
  label: string;
  start_at: string | null;
  end_at: string | null;
  is_target_free_user: boolean;
  is_target_vip_user: boolean;
  is_target_new_user: boolean;
  is_target_old_user: boolean;
  target_minimal_required_ios_app_version: [number, number, number];
  target_minimal_required_android_app_version: [number, number, number];
  created_at: string;
  updated_at: string;
  is_pinned: boolean;
  is_countries_exclusive_on_form: boolean;
  countries: string[];
};

type LinkBaseStartFeedCardDto = BaseStartFeedCardDto & {
  payload_type: StartFeedCardTypeEnum.Link;
  payload: {
    link: string;
    image_url: string;
    image_signed_id: string;
  };
};

type TemplateCategoryBaseStartFeedCardDto = BaseStartFeedCardDto & {
  payload_type: StartFeedCardTypeEnum.TemplateCategory;
  payload: {
    chips: {
      id: number;
      name: string;
      template_tag_id: number;
      position: number;
    }[];
  };
};

export type StartFeedCardDto = LinkBaseStartFeedCardDto | TemplateCategoryBaseStartFeedCardDto;

export const parseStartFeedCardDto = (
  allCountries: CountryModel[],
  dto: StartFeedCardDto,
): PersistedStartFeedCardModel => {
  const startDate = dto.start_at ? DateVo.parseTimeString(dto.start_at) : null;
  const endDate = dto.end_at ? DateVo.parseTimeString(dto.end_at) : null;
  const targetMinimalRequiredIsoAppVersion = AppVersionVo.create(...dto.target_minimal_required_ios_app_version);
  const targetMinimalRequiredAndroiAppVersion = AppVersionVo.create(...dto.target_minimal_required_android_app_version);
  let countries: string[] = [];
  if (dto.is_countries_exclusive_on_form) {
    countries = allCountries
      .filter((c) => {
        return dto.countries.indexOf(c.getIsoCode()) === -1;
      })
      .map((c) => c.getIsoCode());
  } else {
    countries = dto.countries;
  }

  if (dto.payload_type === StartFeedCardTypeEnum.Link) {
    return PersistedLinkStartFeedCardModel.create(
      dto.id,
      dto.analytic_id,
      dto.title,
      dto.subtitle,
      dto.label,
      startDate,
      endDate,
      dto.is_pinned,
      dto.is_countries_exclusive_on_form,
      countries,
      dto.is_target_free_user,
      dto.is_target_vip_user,
      dto.is_target_new_user,
      dto.is_target_old_user,
      targetMinimalRequiredIsoAppVersion,
      targetMinimalRequiredAndroiAppVersion,
      dto.payload_type,
      dto.payload_id,
      dto.payload.link,
      dto.payload.image_url,
      dto.payload.image_signed_id,
    );
  } else {
    return PersistedTemplateCategoryStartFeedCardModel.create(
      dto.id,
      dto.analytic_id,
      dto.title,
      dto.subtitle,
      dto.label,
      startDate,
      endDate,
      dto.is_pinned,
      dto.is_countries_exclusive_on_form,
      countries,
      dto.is_target_free_user,
      dto.is_target_vip_user,
      dto.is_target_new_user,
      dto.is_target_old_user,
      targetMinimalRequiredIsoAppVersion,
      targetMinimalRequiredAndroiAppVersion,
      dto.payload_type,
      dto.payload_id,
      dto.payload.chips.map((chip) =>
        PersistedTemplateCategoryStartFeedCardChipModel.create(chip.id, chip.name, chip.template_tag_id),
      ),
    );
  }
};
