import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import {
  PicButton,
  PicConfirmer,
  PicDivider,
  PicFontSize,
  PicFontWeight,
  PicIconButton,
  PicIconName,
  PicNotifier,
  PicReorderItemsPanel,
  PicRouter,
  PicSearch,
  PicText,
  usePicBulkEditItems,
} from '@cardinalblue/pic-collage-cms-ui';
import { TemplateCategoryCard } from '../features/template-tags/components/template-category-card';
import { TemplateTagApi } from '../features/template-tags/apis/template-tag-api';
import { TemplateTagModel } from '../features/common/models/template-tag-model';
import { ContentRenderer } from '../features/common/components/renderers/content-renderer';

type Props = {
  formAuthenticityToken: string;
};

const NewTemplateCategoryPageContent: React.FC<Props> = ({ formAuthenticityToken }) => {
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const picRouter = React.useMemo(() => new PicRouter(), []);
  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const [isPageVisible, setIsPageVisible] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const templateTagApi = React.useMemo(() => TemplateTagApi.create(), []);
  const [allTemplateTags, setAllTemplateTags] = React.useState<TemplateTagModel[]>([]);
  const [isFetchingTemplateTags, setIsFetchingTemplateTags] = React.useState(false);
  const templateTagCloner = React.useCallback((templateTag: TemplateTagModel) => templateTag.clone(), []);
  const templateTagKeyGetter = React.useCallback((templateTag: TemplateTagModel) => templateTag.getId(), []);
  const templateTagFilter = React.useCallback(
    (templateTag: TemplateTagModel) => {
      return templateTag.getName().toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) > -1;
    },
    [keyword],
  );

  const fetchAllTemplateTags = React.useCallback(async () => {
    setAllTemplateTags([]);
    setKeyword('');

    setIsFetchingTemplateTags(true);
    const [error, returnedTemplateTags] = await templateTagApi.fetchAllTemplateTags();
    setIsFetchingTemplateTags(false);

    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return error;
    }
    setAllTemplateTags(returnedTemplateTags);
    return null;
  }, [templateTagApi, picNotifier, setAllTemplateTags, setKeyword]);

  React.useEffect(() => {
    fetchAllTemplateTags();
  }, [fetchAllTemplateTags]);

  const {
    draftItems: draftTemplateTags,
    filteredDraftItems: filteredDraftTemplateTags,
    selectedDraftItemKeys: selectedDraftTemplateTagKeys,
    hasFilteredOutDraftItems: hasFilteredOutDraftTemplateTags,
    moveSelectedDraftItemsToTop: moveSelectedDraftTemplateTagsToTop,
    moveSelectedDraftItemsToBottom: moveSelectedDraftTemplateTagsToBottom,
    deselectAllSelectedDraftItems: deselectAllSelectedDraftTemplateTags,
    reorderDraftItems: reorderDraftTemplateTags,
    updateSelectedDraftItems: updateSelectedDraftTemplateTags,
    updateDraftItem: updateDraftTemplateTag,
    moveDraftItemToTop: moveDraftTemplateTagToTop,
    moveDraftItemToBottom: moveDraftTemplateTagToBottom,
    selectDraftItems: selectDraftTemplateTags,
  } = usePicBulkEditItems({
    items: allTemplateTags,
    itemCloner: templateTagCloner,
    itemKeyGetter: templateTagKeyGetter,
    itemFilter: templateTagFilter,
  });

  const handleCollapsedToggle = React.useCallback(() => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  }, [setIsCollapsed]);

  const handleTemplateTagsActivinessToggle = React.useCallback(
    (draftTemplateTag: TemplateTagModel) => {
      if (selectedDraftTemplateTagKeys.includes(templateTagKeyGetter(draftTemplateTag))) {
        updateSelectedDraftTemplateTags((previousSelectedDraftTemplateTags) => {
          previousSelectedDraftTemplateTags.forEach((templateTag) => {
            templateTag.updateIsActive(!draftTemplateTag.getIsActive());
          });
          return previousSelectedDraftTemplateTags;
        });
      } else {
        updateDraftTemplateTag(templateTagKeyGetter(draftTemplateTag), (draftTemplateTag) => {
          draftTemplateTag.updateIsActive(!draftTemplateTag.getIsActive());
          return draftTemplateTag;
        });
      }
    },
    [updateSelectedDraftTemplateTags, updateDraftTemplateTag],
  );

  const handleTemplateTagRandomizableChange = React.useCallback(
    (draftTemplateTag: TemplateTagModel) => {
      if (selectedDraftTemplateTagKeys.includes(templateTagKeyGetter(draftTemplateTag))) {
        updateSelectedDraftTemplateTags((previousSelectedDraftTemplateTags) => {
          previousSelectedDraftTemplateTags.forEach((templateTag) => {
            templateTag.updateRandomizable(!draftTemplateTag.getRandomizable());
          });
          return previousSelectedDraftTemplateTags;
        });
      } else {
        updateDraftTemplateTag(templateTagKeyGetter(draftTemplateTag), (draftTemplateTag) => {
          draftTemplateTag.updateRandomizable(!draftTemplateTag.getRandomizable());
          return draftTemplateTag;
        });
      }
    },
    [updateSelectedDraftTemplateTags, updateDraftTemplateTag],
  );

  const handleMoveTemplateTagToTopClick = React.useCallback(
    (draftTemplateTag: TemplateTagModel) => {
      if (selectedDraftTemplateTagKeys.includes(templateTagKeyGetter(draftTemplateTag))) {
        moveSelectedDraftTemplateTagsToTop();
      } else {
        moveDraftTemplateTagToTop(templateTagKeyGetter(draftTemplateTag));
      }
    },
    [moveSelectedDraftTemplateTagsToTop, moveDraftTemplateTagToTop],
  );

  const handleMoveTemplateTagToBottomClick = React.useCallback(
    (draftTemplateTag: TemplateTagModel) => {
      if (selectedDraftTemplateTagKeys.includes(templateTagKeyGetter(draftTemplateTag))) {
        moveSelectedDraftTemplateTagsToBottom();
      } else {
        moveDraftTemplateTagToBottom(templateTagKeyGetter(draftTemplateTag));
      }
    },
    [moveSelectedDraftTemplateTagsToBottom, moveDraftTemplateTagToBottom],
  );

  const saveTemplateTagsOrder = React.useCallback(async (): Promise<Error | null> => {
    if (!draftTemplateTags) {
      return new Error('No template tag');
    }

    const hasTemplateTagOrderChanged = draftTemplateTags.some(
      (draftTemplateTag, index) => draftTemplateTag.getId() !== allTemplateTags[index].getId(),
    );
    // If the order of the template tags has not changed, we can skip the update if only the active status has not changed
    if (!hasTemplateTagOrderChanged) {
      const hasActiveChanged = draftTemplateTags.some(
        (draftTemplateTag, index) => draftTemplateTag.getIsActive() !== allTemplateTags[index].getIsActive(),
      );
      if (!hasActiveChanged) {
        const hasRandomizeChanged = draftTemplateTags.some(
          (draftTemplateTag, index) => draftTemplateTag.getRandomizable() !== allTemplateTags[index].getRandomizable(),
        );
        if (!hasRandomizeChanged) {
          return null;
        }
      }
    }

    return await templateTagApi.updateAllTemplateTags(formAuthenticityToken, draftTemplateTags);
  }, [formAuthenticityToken, draftTemplateTags, allTemplateTags, templateTagApi]);

  const handleTemplateTagSaveClick = React.useCallback(
    async (close: () => void) => {
      let error = await saveTemplateTagsOrder();
      if (error) {
        picNotifier.notify({ type: 'error', message: error.message });
        return;
      }
      picNotifier.notify({ type: 'success', message: 'Successfully saved all updated template tags!' });

      deselectAllSelectedDraftTemplateTags();
      close();

      error = await fetchAllTemplateTags();
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
      }
    },
    [saveTemplateTagsOrder, picNotifier, fetchAllTemplateTags],
  );

  const handleDeleteTemplateTagClick = React.useCallback(
    async (templateTag: TemplateTagModel) => {
      const [confirmed, close] = await picConfirmer.pop({
        title: 'Delete Template Tag',
        message: `Are you sure you want to delete the template tag #${templateTag.getId()} ${templateTag.getName()}?`,
      });
      if (!confirmed) {
        close();
        return;
      }

      let error = await templateTagApi.deleteTemplateTag(formAuthenticityToken, templateTag);
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
        close();
        return;
      }

      picNotifier.notify({
        type: 'success',
        message: `Successfully deleted the template tag #${templateTag.getId()} ${templateTag.getName()}!`,
      });

      close();

      error = await fetchAllTemplateTags();
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
      }
    },
    [formAuthenticityToken, templateTagApi, picNotifier, picConfirmer, fetchAllTemplateTags],
  );

  const goToEditTemplateTagPage = React.useCallback(
    (templateTag: TemplateTagModel) => {
      return () => {
        picRouter.open(`admin/template_tags/${templateTag.getId()}/edit`);
      };
    },
    [picRouter],
  );

  return (
    <div style={{ marginTop: '40px', display: 'flex', flexFlow: 'column', gap: '20px', paddingBottom: '60px' }}>
      <div>
        <PicButton
          type="primary"
          copy={isPageVisible ? 'Hide new UI' : 'Show new UI'}
          onClick={() => {
            setIsPageVisible((prev) => !prev);
          }}
        />
      </div>
      <div style={{ display: isPageVisible ? 'block' : 'none' }}>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <PicText size={PicFontSize.Xl} weight={PicFontWeight.Medium}>
              Template Categories
            </PicText>
            <div style={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
              <div style={{ flexBasis: '80%', display: 'flex' }}>
                <PicSearch placeholder="Search for categories" value={keyword} onInput={setKeyword} />
              </div>
              <div style={{ flexBasis: '20%', display: 'flex', gap: '8px' }}>
                <PicIconButton
                  size="large"
                  iconName={PicIconName.List}
                  disabled={isCollapsed}
                  onClick={handleCollapsedToggle}
                />
                <PicIconButton
                  size="large"
                  iconName={PicIconName.BulletedList}
                  disabled={!isCollapsed}
                  onClick={handleCollapsedToggle}
                />
              </div>
            </div>
          </div>
          <PicDivider marginTop="0px" marginBottom="0px" />
          <ContentRenderer loading={isFetchingTemplateTags} noContent={draftTemplateTags.length === 0}>
            <PicReorderItemsPanel
              items={filteredDraftTemplateTags}
              column={1}
              itemKeyGetter={templateTagKeyGetter}
              selectedItemKeys={selectedDraftTemplateTagKeys}
              onSelectedItemKeysChange={selectDraftTemplateTags}
              disableDragging={hasFilteredOutDraftTemplateTags}
              disableSelection={hasFilteredOutDraftTemplateTags}
              onItemsReorder={(draftTemplateTags) => {
                reorderDraftTemplateTags(draftTemplateTags.map(templateTagKeyGetter));
              }}
              itemRenderer={({ item: draftTemplateTag, itemIndex, selected, onSelect }) => {
                return (
                  <TemplateCategoryCard
                    order={itemIndex + 1}
                    isCollapsed={isCollapsed}
                    editable={true}
                    selected={selected}
                    templateTag={draftTemplateTag}
                    onClick={onSelect}
                    onTitleClick={goToEditTemplateTagPage(draftTemplateTag)}
                    onRandomizeClick={() => handleTemplateTagRandomizableChange(draftTemplateTag)}
                    onMoveToTopClick={() => handleMoveTemplateTagToTopClick(draftTemplateTag)}
                    onMoveToBottomClick={() => handleMoveTemplateTagToBottomClick(draftTemplateTag)}
                    onActivenessClick={() => handleTemplateTagsActivinessToggle(draftTemplateTag)}
                    onDeleteClick={() => handleDeleteTemplateTagClick(draftTemplateTag)}
                  />
                );
              }}
            />
          </ContentRenderer>
        </div>
      </div>
      <footer
        style={{
          display: isPageVisible ? 'flex' : 'none',
          justifyContent: 'center',
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          background: 'white',
          textAlign: 'center',
          padding: '10px',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          zIndex: 100,
        }}
      >
        <PicButton
          type="primary"
          size="lg"
          outlined={false}
          copy="Save"
          onClick={async () => {
            const [confirmed, close] = await picConfirmer.pop({
              title: 'Save Changes?',
              message: 'Are you sure you want to save your changes to the template tags?',
            });
            if (!confirmed) {
              close();
              return;
            }
            handleTemplateTagSaveClick(close);
          }}
        />
      </footer>
    </div>
  );
};

const NewTemplateCategoryPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <NewTemplateCategoryPageContent {...props} />
    </CommonContextProvider>
  );
};

export default NewTemplateCategoryPage;
