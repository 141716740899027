import { TemplateModel } from '../models/template-model';
import { TemplateSizeCateoryEnum } from '../models/template-size-category-enum';

export type TemplateDto = {
  id: number;
  is_vip: boolean;
  image_url: string;
  is_animated: boolean;
  is_active: boolean;
  size_category: TemplateSizeCateoryEnum;
  slots_count: number;
  created_at: string;
  updated_at: string;
  template_tags: {
    id: number;
    name: string;
  }[];
};

export const parseTemplateDto = (dto: TemplateDto): TemplateModel => {
  return TemplateModel.create(
    dto.id,
    dto.is_vip,
    dto.image_url,
    dto.is_animated,
    dto.is_active,
    dto.size_category,
    dto.slots_count,
    dto.created_at,
    dto.updated_at,
    dto.template_tags.map((tag) => tag.id),
  );
};
