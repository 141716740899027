import { CountryModel } from '../models/country-model';

export type CountryDto = {
  id: number;
  country_code: string;
};

export const parseCountryDto = (dto: CountryDto): CountryModel => {
  return CountryModel.create(dto.id, dto.country_code);
};
