import { NewLinkStartFeedCardModel } from '../models/link-start-feed-cards/new-link-start-feed-card-model';
import { PersistedLinkStartFeedCardModel } from '../models/link-start-feed-cards/persisted-link-start-feed-card-model';
import { NewStartFeedCardModel } from '../models/new-start-feed-card-model';
import { PersistedStartFeedCardModel } from '../models/persisted-start-feed-card-model';
import { StartFeedCardModel } from '../models/start-feed-card-model';
import { StartFeedCardTypeEnum } from '../models/start-feed-card-type-enum';
import { PersistedTemplateCategoryStartFeedCardModel } from '../models/template-category-start-feed-cards/persisted-template-category-start-feed-card-model';
import { PersistedTemplateCategoryStartFeedCardChipModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-chips/persisted-template-category-start-feed-card-chip-model';
import { dispatchStartFeedCardModel } from '../models/utils';

export class StartFeedCardApi {
  static create() {
    return new StartFeedCardApi();
  }

  private fillFormWithBasicInfo(formData: FormData, startFeedCard: StartFeedCardModel) {
    formData.append('analytic_id', startFeedCard.getAnalyticId());
    formData.append('title', startFeedCard.getTitle());

    const subtitle = startFeedCard.getSubtitle();
    formData.append('subtitle', subtitle);

    const label = startFeedCard.getLabel();
    formData.append('label', label);

    formData.append('is_pinned', startFeedCard.getIsPinned() + '');

    const startAt = startFeedCard.getStartAt();
    formData.append('start_at', startAt?.toISO8601String(-8) ?? '');
    const endAt = startFeedCard.getEndAt();
    formData.append('end_at', endAt?.toISO8601String(-8) ?? '');

    const countries = startFeedCard.getCountries();
    formData.append('is_countries_exclusive', startFeedCard.getIsCountriesExclusive() + '');
    if (countries.length === 0) {
      formData.append('countries[]', '[]');
    } else {
      countries.forEach((country) => {
        formData.append(`countries[]`, country.toUpperCase());
      });
    }

    const iosAppVersion = startFeedCard.getTargetMinimalRequiredIosAppVersion();
    formData.append(`target_minimal_required_ios_app_version[]`, iosAppVersion.getMajor().toString());
    formData.append(`target_minimal_required_ios_app_version[]`, iosAppVersion.getMinor().toString());
    formData.append(`target_minimal_required_ios_app_version[]`, iosAppVersion.getPatch().toString());

    const androiAppVersion = startFeedCard.getTargetMinimalRequiredAndroidAppVersion();
    formData.append(`target_minimal_required_android_app_version[]`, androiAppVersion.getMajor().toString());
    formData.append(`target_minimal_required_android_app_version[]`, androiAppVersion.getMinor().toString());
    formData.append(`target_minimal_required_android_app_version[]`, androiAppVersion.getPatch().toString());

    formData.append('is_target_free_user', startFeedCard.getIsTargetFreeUser() + '');
    formData.append('is_target_vip_user', startFeedCard.getIsTargetVipUser() + '');
    formData.append('is_target_new_user', startFeedCard.getIsTargetNewUser() + '');
    formData.append('is_target_old_user', startFeedCard.getIsTargetOldUser() + '');
  }

  private generateCompleteStartFeedCardModelFormData(startFeedCard: StartFeedCardModel): FormData {
    const formData = new FormData();

    this.fillFormWithBasicInfo(formData, startFeedCard);

    dispatchStartFeedCardModel(startFeedCard, {
      [StartFeedCardTypeEnum.Link]: (linkStartFeedCard) => {
        formData.append('type', StartFeedCardTypeEnum.Link);

        const image = linkStartFeedCard.getImage();
        formData.append('payload[link]', linkStartFeedCard.getLink());
        if (linkStartFeedCard instanceof NewLinkStartFeedCardModel) {
          const imageSignedId = linkStartFeedCard.getImageSignedId();
          /**
           * 1. If image is present, then we upload new image for the new link card.
           * 2. If image is not present and the imageSignedId is present, we reference the existing image to the new link card,
           *    this use case is for the clone link card feature.
           */
          if (image) {
            formData.append('payload[image]', new Blob([image]));
          } else if (imageSignedId) {
            formData.append('payload[image]', imageSignedId);
          }
        } else if (linkStartFeedCard instanceof PersistedLinkStartFeedCardModel) {
          if (image) {
            formData.append('payload[image]', new Blob([image]));
          }
        }
      },
      [StartFeedCardTypeEnum.TemplateCategory]: (templateCategoryStartFeedCard) => {
        formData.append('type', StartFeedCardTypeEnum.TemplateCategory);

        const chips = templateCategoryStartFeedCard.getChips();
        if (chips.length === 0) {
          // TODO - ideally this shouldn't happen, but if this does happen, we need to handle this
        } else {
          const chips = templateCategoryStartFeedCard.getChips();

          chips.forEach((chip, chipIndex) => {
            if (chip instanceof PersistedTemplateCategoryStartFeedCardChipModel)
              formData.append(`payload[chips_attributes][${chipIndex}][id]`, chip.getId() + '');
            formData.append(`payload[chips_attributes][${chipIndex}][name]`, chip.getName());
            formData.append(`payload[chips_attributes][${chipIndex}][template_tag_id]`, chip.getTemplateTagId() + '');
            formData.append(`payload[chips_attributes][${chipIndex}][position]`, chipIndex + '');
          });
          if (templateCategoryStartFeedCard instanceof PersistedTemplateCategoryStartFeedCardModel) {
            const chipsToRemove = templateCategoryStartFeedCard.getChipsToRemove();
            chipsToRemove.forEach((chip, chipIndex) => {
              const shiftedIndex = chips.length + chipIndex;
              formData.append(`payload[chips_attributes][${shiftedIndex}][id]`, chip.getId() + '');
              formData.append(`payload[chips_attributes][${shiftedIndex}][name]`, chip.getName());
              formData.append(
                `payload[chips_attributes][${shiftedIndex}][template_tag_id]`,
                chip.getTemplateTagId() + '',
              );
              formData.append(`payload[chips_attributes][${shiftedIndex}][position]`, shiftedIndex + '');
              formData.append(`payload[chips_attributes][${shiftedIndex}][_destroy]`, 'true');
            });
          }
        }
      },
    });

    return formData;
  }

  async create(startFeedCard: NewStartFeedCardModel): Promise<[error: Error | null, id: number]> {
    const formData = this.generateCompleteStartFeedCardModelFormData(startFeedCard);

    const response = await fetch(`/cms_api/start_feed_cards`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return [new Error(error), -1];
    } else {
      const { id } = (await response.json()) as { id: number };
      return [null, id];
    }
  }

  async updateOrder(startFeedCardIds: number[]): Promise<Error | null> {
    const response = await fetch('/cms_api/start_feed_cards/ordering', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ordering: startFeedCardIds,
      }),
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }

  async bulkUpdate(startFeedCards: PersistedStartFeedCardModel[]): Promise<void> {
    await Promise.all(
      startFeedCards.map(async (startFeedCard) => {
        const formData = new FormData();
        formData.append('is_pinned', startFeedCard.getIsPinned() + '');

        const response = await fetch(`/cms_api/start_feed_cards/${startFeedCard.getId()}`, {
          method: 'PATCH',
          body: formData,
        });

        if (!response.ok) {
          const { error } = (await response.json()) as { error: string };
          return new Error(error);
        } else {
          return null;
        }
      }),
    );
  }

  async update(startFeedCard: PersistedStartFeedCardModel): Promise<Error | null> {
    const formData = this.generateCompleteStartFeedCardModelFormData(startFeedCard);

    const response = await fetch(`/cms_api/start_feed_cards/${startFeedCard.getId()}`, {
      method: 'PATCH',
      body: formData,
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }

  async delete(formAuthenticityToken: string, startFeedCardId: number): Promise<Error | null> {
    const response = await fetch(`/admin/start_feed_cards/${startFeedCardId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': formAuthenticityToken,
      },
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }
}
