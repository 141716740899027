import * as React from 'react';
import { PicDropdown } from '@cardinalblue/pic-collage-cms-ui';
import { TemplateTagModel } from '../../../models/template-tag-model';
import { doesStringStartWithEmoji, doesStringStartWithEnglishOrDigit } from '../../../utils/string';

type Props = {
  value: number | null;
  templateTags: TemplateTagModel[];
  onSelect: (templateTag: TemplateTagModel) => void;
};

type TemplateTagItemGroups = { title: 'Emoji' | 'Regular' | 'Localized'; items: TemplateTagModel[] }[];

export function TemplateTagDropdown({ value, templateTags, onSelect }: Props) {
  const templateTagGroups: TemplateTagItemGroups = React.useMemo(() => {
    const groups: TemplateTagItemGroups = [
      {
        title: 'Emoji',
        items: [],
      },
      {
        title: 'Regular',
        items: [],
      },
      {
        title: 'Localized',
        items: [],
      },
    ];

    templateTags.forEach((tag) => {
      if (doesStringStartWithEmoji(tag.getName())) {
        groups[0].items.push(tag);
      } else if (doesStringStartWithEnglishOrDigit(tag.getName())) {
        groups[1].items.push(tag);
      } else {
        groups[2].items.push(tag);
      }
    });

    groups[1].items.sort((itemA, itemB) => itemA.getName().toLowerCase().localeCompare(itemB.getName().toLowerCase()));

    return groups;
  }, [templateTags]);

  return (
    <PicDropdown
      value={value ? `${value}` : null}
      placeholder="Select a template tag"
      itemGroups={templateTagGroups}
      enableFilter
      itemLabelGetter={(tag) => tag.getName()}
      itemValueGetter={(tag) => tag.getId() + ''}
      onSelect={onSelect}
    />
  );
}
