import { AppVersionVo } from '../models/app-version-vo';
import { DateVo } from '../models/date-vo';
import { TemplateModel } from '../models/template-model';
import { TemplateTagModel } from '../models/template-tag-model';
import { TemplateDto } from './template-dto';

export type TemplateTagDto = {
  id: number;
  name: string;
  priority: number;
  randomizable: boolean;
  is_magic_mode: boolean;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  is_niche: boolean;
  need_feature_compatibility_check: boolean;
  minimal_required_version_ios?: number[];
  minimal_required_version_android?: number[];
  preview_active_templates?: TemplateDto[];
};

export const parseTemplateTagDto = (dto: TemplateTagDto): TemplateTagModel => {
  return TemplateTagModel.create(
    dto.id,
    dto.name,
    dto.priority,
    dto.randomizable,
    dto.is_magic_mode,
    dto.is_active,
    DateVo.parseTimeString(dto.created_at),
    DateVo.parseTimeString(dto.created_at),
    dto.is_niche,
    dto.need_feature_compatibility_check,
    dto.minimal_required_version_ios
      ? AppVersionVo.create(
          dto.minimal_required_version_ios[0] || 0,
          dto.minimal_required_version_ios[1] || 0,
          dto.minimal_required_version_ios[2] || 0,
        )
      : null,
    dto.minimal_required_version_android
      ? AppVersionVo.create(
          dto.minimal_required_version_android[0] || 0,
          dto.minimal_required_version_android[1] || 0,
          dto.minimal_required_version_android[2] || 0,
        )
      : null,
    dto.preview_active_templates
      ? dto.preview_active_templates.map((preview_active_template) =>
          TemplateModel.create(
            preview_active_template.id,
            preview_active_template.is_vip,
            preview_active_template.image_url,
            preview_active_template.is_animated,
            preview_active_template.is_active,
            preview_active_template.size_category,
            preview_active_template.slots_count,
            preview_active_template.created_at,
            preview_active_template.updated_at,
            preview_active_template.template_tags.map((tag) => tag.id),
          ),
        )
      : [],
  );
};
