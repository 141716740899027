import * as React from 'react';
import { PicField, PicText } from '@cardinalblue/pic-collage-cms-ui';
import { PersistedTemplateCategoryStartFeedCardModel } from '../models/template-category-start-feed-cards/persisted-template-category-start-feed-card-model';
import { ListDataPagePanel } from '../../common/components/panels/list-data-page-panel';
import { TemplateTagModel } from '../../common/models/template-tag-model';

type Props = {
  templateCategroyStartFeedCard: PersistedTemplateCategoryStartFeedCardModel;
  templateTags: TemplateTagModel[];
};

export function ShowTemplateCategoryStartFeedCardPanel({ templateCategroyStartFeedCard, templateTags }: Props) {
  const chips = React.useMemo(() => templateCategroyStartFeedCard.getChips(), [templateCategroyStartFeedCard]);
  const getTemplateTagName = React.useCallback(
    (templateTagId: number | null) => {
      if (!templateTagId) return '';

      return templateTags.find((t) => t.getId() === templateTagId)?.getName() || '';
    },
    [templateTags],
  );

  return (
    <div style={{ width: '100%', display: 'flex', flexFlow: 'column', gap: '12px' }}>
      {chips.map((chip, chipIdx) => (
        <ListDataPagePanel key={`${chipIdx}`} title={`#${chipIdx + 1} Chip`}>
          <PicField label="Name">
            <PicText>{chip.getName() || 'None'}</PicText>
          </PicField>
          <PicField label="Template Tag">
            <PicText>{getTemplateTagName(chip.getTemplateTagId())}</PicText>
          </PicField>
        </ListDataPagePanel>
      ))}
    </div>
  );
}
