import * as React from 'react';
import { PicColor, PicFontSize, PicFontWeight, PicTag, PicText } from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  title: string;
  subtitle?: string;
  tags?: string[];
  ctaButtons?: React.ReactNode;
};

export function ActionableHeader({ title, tags = [], subtitle, ctaButtons }: Props) {
  return (
    <div
      style={{
        height: '35px',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <PicText size={PicFontSize.Lg} weight={PicFontWeight.Medium}>
          {title}
        </PicText>
        {subtitle && <PicText size={PicFontSize.Lg}>{`(${subtitle})`}</PicText>}
        <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
          {tags.map((tag) => (
            <PicTag key={tag} copy={tag} color={PicColor.Teal500} />
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', flexFlow: 'row', gap: '4px' }}>{ctaButtons}</div>
    </div>
  );
}
