import { IStartFeedCardModel } from '../interfaces';
import { BaseStartFeedCardParams, StartFeedCardModel } from '../start-feed-card-model';

export abstract class LinkStartFeedCardModel extends StartFeedCardModel implements IStartFeedCardModel {
  constructor(
    params: BaseStartFeedCardParams,
    protected link: string,
    protected image: Uint8Array | null,
  ) {
    super(params);
  }

  abstract clone(): LinkStartFeedCardModel;

  abstract getImageUrl(): string | null;

  /**
   * This will be used when cloning a new link card, instead of re-downloading the image, we use signed_id to re-reference
   * the image to the new link card.
   */
  abstract getImageSignedId(): string | null;

  public getLink(): string {
    return this.link;
  }

  public updateLink(link: string): void {
    this.link = link.trim();
  }

  public getImage(): Uint8Array | null {
    return this.image;
  }

  public updateImage(image: Uint8Array | null): void {
    this.image = image;
  }
}
