import * as React from 'react';
import { PicUiContextProvider } from '@cardinalblue/pic-collage-cms-ui';

type ContextValue = {};

function createInitialContextValue(): ContextValue {
  return {};
}

/**
 * Provide all contexts that are shared among all pages
 */
const Context = React.createContext<ContextValue>(createInitialContextValue());

function Provider({ children }: React.PropsWithChildren<{}>) {
  return (
    <Context.Provider value={React.useMemo<ContextValue>(() => ({}), [])}>
      <PicUiContextProvider>{children}</PicUiContextProvider>
    </Context.Provider>
  );
}

export { Provider as CommonContextProvider, Context as CommonContext };
