import { AppVersionVo } from '../../../common/models/app-version-vo';
import { generateObjectUrlFromUnit8Array } from '../../../common/utils/file';
import { INewStartFeedCardModel } from '../interfaces';
import { BaseStartFeedCardParams } from '../start-feed-card-model';
import { StartFeedCardTypeEnum } from '../start-feed-card-type-enum';
import { LinkStartFeedCardModel } from './link-start-feed-card-model';

export class NewLinkStartFeedCardModel extends LinkStartFeedCardModel implements INewStartFeedCardModel {
  constructor(
    params: BaseStartFeedCardParams,
    link: string,
    image: Uint8Array | null,
    protected imageUrl: string | null,
    protected imageSignedId: string | null,
  ) {
    super(params, link, image);
  }

  static create(
    params: BaseStartFeedCardParams,
    link: string,
    image: Uint8Array | null,
    imageUrl: string,
    imageSignedId: string,
  ): NewLinkStartFeedCardModel {
    return new NewLinkStartFeedCardModel(params, link, image, imageUrl, imageSignedId);
  }

  static createNew(): NewLinkStartFeedCardModel {
    return new NewLinkStartFeedCardModel(
      {
        analyticId: '',
        title: '',
        subtitle: '',
        label: '',
        startAt: null,
        endAt: null,
        isPinned: false,
        isCountriesExclusive: false,
        countries: ['US', 'TW'],
        isTargetFreeUser: true,
        isTargetVipUser: true,
        isTargetNewUser: true,
        isTargetOldUser: true,
        targetMinimalRequiredIosAppVersion: AppVersionVo.create(0, 0, 0),
        targetMinimalRequiredAndroidAppVersion: AppVersionVo.create(0, 0, 0),
        payloadType: StartFeedCardTypeEnum.Link,
      },
      '',
      null,
      null,
      null,
    );
  }

  public clone(): NewLinkStartFeedCardModel {
    return new NewLinkStartFeedCardModel(
      this.generateBaseStartFeedCardParams(),
      this.link,
      this.image,
      this.imageUrl,
      this.imageSignedId,
    );
  }

  public isComplete(): boolean {
    return !!this.analyticId && !!this.title && !!this.link && !!this.getImageUrl();
  }

  public getImageUrl(): string | null {
    const image = this.getImage();
    if (!image) return this.imageUrl;

    return generateObjectUrlFromUnit8Array(image);
  }

  /**
   * If this new link card is from an exisintg link card, it will have image signed id from the source link card.
   * For a brand new link card, this will be empty.
   */
  public getImageSignedId(): string | null {
    return this.imageSignedId;
  }
}
