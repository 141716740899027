import * as React from 'react';
import {
  PicColor,
  PicContainer,
  PicField,
  PicFontWeight,
  PicIconButton,
  PicIconName,
  PicInput,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';
import { TemplateCategoryStartFeedCardChipModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-chips/template-category-start-feed-card-chip-model';
import { TemplateTagModel } from '../../common/models/template-tag-model';
import { TemplateTagDropdown } from '../../common/components/dropdowns/template-tag-dropdown';

type Props<T> = {
  templateTags: TemplateTagModel[];
  chip: T;
  chipIndex: number;
  removable?: boolean;
  isNameRequired: boolean;
  onChipUpdate: (updatedStartFeedCard: T) => void;
  onRemoveClick: () => void;
  chipCloner: (chip: T) => T;
};

export function EditTemplateCategoryStartFeedCardChipPanel<T extends TemplateCategoryStartFeedCardChipModel>({
  templateTags,
  chip,
  chipIndex,
  removable = true,
  isNameRequired,
  onChipUpdate,
  onRemoveClick,
  chipCloner,
}: Props<T>) {
  const cloneChipToUpdate = React.useCallback(
    (cb: (clonedChip: T) => T) => {
      onChipUpdate(cb(chipCloner(chip)));
    },
    [chip],
  );

  return (
    <PicContainer borderRadius="7px" background={PicColor.White}>
      <div
        style={{
          position: 'relative',
          padding: '20px',
          display: 'flex',
          flexFlow: 'column',
          gap: '20px',
          cursor: 'pointer',
        }}
      >
        {removable && (
          <div style={{ position: 'absolute', top: 4, right: 4 }}>
            <PicIconButton iconName={PicIconName.Close} onClick={onRemoveClick} />
          </div>
        )}
        <PicText weight={PicFontWeight.Medium}>{`#${chipIndex + 1}`}</PicText>
        <PicField
          label={`Name${isNameRequired ? ' (Required)' : ''}`}
          description={isNameRequired ? 'Name is required when there are more than one chip' : undefined}
        >
          <PicInput
            value={chip.getName()}
            onInput={(val) => {
              cloneChipToUpdate((clonedChip) => {
                clonedChip.updateName(val);
                return clonedChip;
              });
            }}
          />
        </PicField>
        <PicField label="Template Tag (Required)">
          <TemplateTagDropdown
            value={chip.getTemplateTagId()}
            templateTags={templateTags}
            onSelect={(newTemplateTagId) => {
              cloneChipToUpdate((clonedChip) => {
                clonedChip.updateTemplateTagId(newTemplateTagId.getId());
                return clonedChip;
              });
            }}
          />
        </PicField>
      </div>
    </PicContainer>
  );
}
