import { AppVersionVo } from '../../common/models/app-version-vo';
import { DateVo } from '../../common/models/date-vo';
import { IStartFeedCardModel } from './interfaces';
import { StartFeedCardTypeEnum } from './start-feed-card-type-enum';

export type BaseStartFeedCardParams = {
  analyticId: string;
  title: string;
  subtitle: string;
  label: string;
  startAt: DateVo | null;
  endAt: DateVo | null;
  isPinned: boolean;
  isCountriesExclusive: boolean;
  countries: string[];
  isTargetFreeUser: boolean;
  isTargetVipUser: boolean;
  isTargetNewUser: boolean;
  isTargetOldUser: boolean;
  targetMinimalRequiredIosAppVersion: AppVersionVo;
  targetMinimalRequiredAndroidAppVersion: AppVersionVo;
  payloadType: StartFeedCardTypeEnum;
};

export abstract class StartFeedCardModel implements IStartFeedCardModel {
  protected analyticId: string;

  protected title: string;

  protected subtitle: string;

  protected label: string;

  protected startAt: DateVo | null;

  protected endAt: DateVo | null;

  protected isPinned: boolean;

  protected isCountriesExclusive: boolean;

  protected countries: string[];

  protected isTargetFreeUser: boolean;

  protected isTargetVipUser: boolean;

  protected isTargetNewUser: boolean;

  protected isTargetOldUser: boolean;

  protected targetMinimalRequiredIosAppVersion: AppVersionVo;

  protected targetMinimalRequiredAndroidAppVersion: AppVersionVo;

  protected payloadType: StartFeedCardTypeEnum;

  constructor(params: BaseStartFeedCardParams) {
    this.analyticId = params.analyticId;
    this.title = params.title;
    this.subtitle = params.subtitle;
    this.label = params.label;
    this.startAt = params.startAt;
    this.endAt = params.endAt;
    this.isPinned = params.isPinned;
    this.isCountriesExclusive = params.isCountriesExclusive;
    this.countries = params.countries;
    this.isTargetFreeUser = params.isTargetFreeUser;
    this.isTargetVipUser = params.isTargetVipUser;
    this.isTargetNewUser = params.isTargetNewUser;
    this.isTargetOldUser = params.isTargetOldUser;
    this.targetMinimalRequiredIosAppVersion = params.targetMinimalRequiredIosAppVersion;
    this.targetMinimalRequiredAndroidAppVersion = params.targetMinimalRequiredAndroidAppVersion;
    this.payloadType = params.payloadType;
  }

  /**
   * Quickly generate params of Start Feed Card
   */
  protected generateBaseStartFeedCardParams(): BaseStartFeedCardParams {
    return {
      analyticId: this.analyticId,
      title: this.title,
      subtitle: this.subtitle,
      label: this.label,
      startAt: this.startAt,
      endAt: this.endAt,
      isPinned: this.isPinned,
      isCountriesExclusive: this.isCountriesExclusive,
      countries: this.countries,
      isTargetFreeUser: this.isTargetFreeUser,
      isTargetVipUser: this.isTargetVipUser,
      isTargetNewUser: this.isTargetNewUser,
      isTargetOldUser: this.isTargetOldUser,
      targetMinimalRequiredIosAppVersion: this.targetMinimalRequiredIosAppVersion,
      targetMinimalRequiredAndroidAppVersion: this.targetMinimalRequiredAndroidAppVersion,
      payloadType: this.payloadType,
    };
  }

  abstract clone(): StartFeedCardModel;

  abstract isComplete(): boolean;

  public getAnalyticId(): string {
    return this.analyticId;
  }

  public updateAnalyticId(analyticId: string): void {
    this.analyticId = analyticId;
  }

  public getTitle(): string {
    return this.title;
  }

  public updateTitle(title: string): void {
    this.title = title;
  }

  public getSubtitle(): string {
    return this.subtitle;
  }

  public updateSubtitle(subtitle: string): void {
    this.subtitle = subtitle;
  }

  public getLabel(): string {
    return this.label;
  }

  public updateLabel(label: string): void {
    this.label = label;
  }

  public getStartAt(): DateVo | null {
    return this.startAt;
  }

  public updateStartAt(startAt: DateVo | null): void {
    this.startAt = startAt;
  }

  public getEndAt(): DateVo | null {
    return this.endAt;
  }

  public updateEndAt(endAt: DateVo | null): void {
    this.endAt = endAt;
  }

  public isLive(): boolean {
    if (!this.startAt) return false;

    if (!this.endAt) return DateVo.create(new Date()).isAfter(this.startAt);

    return DateVo.create(new Date()).isBetween(this.startAt, this.endAt);
  }

  public getIsPinned(): boolean {
    return this.isPinned;
  }

  public updateIsPinned(isPinned: boolean): void {
    this.isPinned = isPinned;
  }

  public getIsCountriesExclusive(): boolean {
    return this.isCountriesExclusive;
  }

  public updateIsCountriesExclusive(isCountriesExclusive: boolean): void {
    this.isCountriesExclusive = isCountriesExclusive;
  }

  public getCountries(): string[] {
    return this.countries;
  }

  public updateCountries(countries: string[]): void {
    this.countries = countries;
  }

  public getIsTargetFreeUser(): boolean {
    return this.isTargetFreeUser;
  }

  public updateIsTargetFreeUser(isTargetFreeUser: boolean): void {
    this.isTargetFreeUser = isTargetFreeUser;
  }

  public getIsTargetVipUser(): boolean {
    return this.isTargetVipUser;
  }

  public updateIsTargetVipUser(isTargetVipUser: boolean): void {
    this.isTargetVipUser = isTargetVipUser;
  }

  public getTargetVipFreeUserCopy(): string {
    if (this.isTargetFreeUser && this.isTargetVipUser) return 'All';
    else if (!this.isTargetFreeUser && this.isTargetVipUser) return 'Vip Users Only';
    else if (this.isTargetFreeUser && !this.isTargetVipUser) return 'Free Users Only';
    else return 'None';
  }

  public getIsTargetNewUser(): boolean {
    return this.isTargetNewUser;
  }

  public updateIsTargetNewUser(isTargetNewUser: boolean): void {
    this.isTargetNewUser = isTargetNewUser;
  }

  public getIsTargetOldUser(): boolean {
    return this.isTargetOldUser;
  }

  public updateIsTargetOldUser(isTargetOldUser: boolean): void {
    this.isTargetOldUser = isTargetOldUser;
  }

  public getTargetNewOldUserCopy(): string {
    if (this.isTargetNewUser && this.isTargetOldUser) return 'All';
    else if (!this.isTargetNewUser && this.isTargetOldUser) return 'Old Users Only';
    else if (this.isTargetNewUser && !this.isTargetOldUser) return 'New Users Only';
    else return 'None';
  }

  public getTargetMinimalRequiredIosAppVersion(): AppVersionVo {
    return this.targetMinimalRequiredIosAppVersion;
  }

  public updateTargetMinimalRequiredIosAppVersion(targetMinimalRequiredIosAppVersion: AppVersionVo): void {
    this.targetMinimalRequiredIosAppVersion = targetMinimalRequiredIosAppVersion;
  }

  public getTargetMinimalRequiredAndroidAppVersion(): AppVersionVo {
    return this.targetMinimalRequiredAndroidAppVersion;
  }

  public updateTargetMinimalRequiredAndroidAppVersion(targetMinimalRequiredAndroidAppVersion: AppVersionVo): void {
    this.targetMinimalRequiredAndroidAppVersion = targetMinimalRequiredAndroidAppVersion;
  }

  public getType(): string {
    switch (this.payloadType) {
      case StartFeedCardTypeEnum.Link:
        return 'Link';
      case StartFeedCardTypeEnum.TemplateCategory:
        return 'Template Category';
    }
  }
}
