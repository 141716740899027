import * as React from 'react';
import {
  PicCheckbox,
  PicColor,
  PicFontSize,
  PicFontWeight,
  PicGrid,
  PicIconButton,
  PicIconName,
  PicLink,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';
import { TemplateTagModel } from '../../common/models/template-tag-model';
import { TemplateCard } from './template-card';
import { TemplateModel } from '../../common/models/template-model';

type Props = {
  order: number;
  isCollapsed: boolean;
  editable: boolean;
  selected: boolean;
  templateTag: TemplateTagModel;
  onClick?: () => void;
  onTitleClick: () => void;
  onRandomizeClick: () => void;
  onMoveToTopClick: () => void;
  onMoveToBottomClick: () => void;
  onActivenessClick: () => void;
  onDeleteClick: () => void;
};

export const TemplateCategoryCard: React.FC<Props> = ({
  order,
  isCollapsed,
  editable,
  selected,
  templateTag,
  onClick,
  onTitleClick,
  onRandomizeClick,
  onMoveToTopClick,
  onMoveToBottomClick,
  onActivenessClick,
  onDeleteClick,
}) => {
  const borderColor = selected ? PicColor.Teal700 : PicColor.White;
  const borderWidth = selected ? '2px' : '1px';
  const backgroundColor = templateTag.getIsActive() ? PicColor.White : PicColor.Grey300;
  const fontColor = undefined;

  return (
    <div
      style={{
        background: backgroundColor,
        border: `${borderWidth} solid ${borderColor || 'none'}`,
      }}
    >
      <div
        key={templateTag.getId()}
        style={{
          padding: '12px 0',
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          cursor: onClick ? 'pointer' : undefined,
        }}
        onClick={onClick}
      >
        <div
          style={{
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PicText size={PicFontSize.Sm} color={fontColor} weight={PicFontWeight.Medium}>
            {order}
          </PicText>
        </div>
        <div
          style={{
            flexGrow: '1',
            padding: '0 12px',
            marginRight: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <div
              style={{
                flexBasis: '25%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  gap: '4px',
                }}
              >
                <PicLink size={PicFontSize.Base} copy={templateTag.getName()} onClick={onTitleClick} />
              </div>
            </div>
            <div
              style={{
                flexBasis: '42.5%',
                display: 'flex',
                visibility: isCollapsed ? 'hidden' : 'visible',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '45%',
                height: isCollapsed ? '0' : 'auto',
                overflow: isCollapsed ? 'hidden' : 'visible',
                gap: '4px',
              }}
            >
              <PicGrid columns={6} padding={0} gap={6}>
                {templateTag.getPreviewActiveTemplates().map((template) => (
                  <div
                    key={template.getId()}
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    {TemplateCard({
                      template: template as TemplateModel,
                      idPosition: 'bottom',
                      idFontWeight: PicFontWeight.Light,
                    })}
                  </div>
                ))}
              </PicGrid>
            </div>
            <span style={{ flexBasis: '7.5%' }} />
            <div
              style={{
                flexBasis: '10%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <div
                style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
              >
                <PicText color={fontColor} size={PicFontSize.Sm}>
                  Randomize
                </PicText>
                <PicCheckbox checked={templateTag.getRandomizable()} onChange={onRandomizeClick} />
              </div>
            </div>
            <span style={{ flexBasis: '2.5%' }} />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <PicIconButton
                size="large"
                disabled={!editable}
                iconName={PicIconName.ArrowTop}
                onClick={onMoveToTopClick}
              />
              <PicIconButton
                size="large"
                disabled={!editable}
                iconName={PicIconName.ArrowBottom}
                onClick={onMoveToBottomClick}
              />
              <PicIconButton
                size="large"
                disabled={!editable}
                iconName={templateTag.getIsActive() ? PicIconName.View : PicIconName.Hide}
                onClick={onActivenessClick}
              />
              <PicIconButton
                color="red"
                size="large"
                disabled={!editable}
                iconName={PicIconName.Delete}
                onClick={onDeleteClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
