import { LinkStartFeedCardModel } from './link-start-feed-cards/link-start-feed-card-model';
import { NewLinkStartFeedCardModel } from './link-start-feed-cards/new-link-start-feed-card-model';
import { PersistedLinkStartFeedCardModel } from './link-start-feed-cards/persisted-link-start-feed-card-model';
import { NewStartFeedCardModel } from './new-start-feed-card-model';
import { PersistedStartFeedCardModel } from './persisted-start-feed-card-model';
import { StartFeedCardModel } from './start-feed-card-model';
import { StartFeedCardTypeEnum } from './start-feed-card-type-enum';
import { NewTemplateCategoryStartFeedCardModel } from './template-category-start-feed-cards/new-template-category-start-feed-card-model';
import { PersistedTemplateCategoryStartFeedCardModel } from './template-category-start-feed-cards/persisted-template-category-start-feed-card-model';
import { TemplateCategoryStartFeedCardModel } from './template-category-start-feed-cards/template-category-start-feed-card-model';

export const isPersistedStartFeedCardModel = (
  startFeedCard: StartFeedCardModel,
): startFeedCard is PersistedStartFeedCardModel => {
  return (
    startFeedCard instanceof PersistedLinkStartFeedCardModel ||
    startFeedCard instanceof PersistedTemplateCategoryStartFeedCardModel
  );
};

export const dispatchStartFeedCardModel = <T extends any>(
  startFeedCardModel: StartFeedCardModel,
  dispatcher: {
    [StartFeedCardTypeEnum.Link]: (model: LinkStartFeedCardModel) => T;
    [StartFeedCardTypeEnum.TemplateCategory]: (model: TemplateCategoryStartFeedCardModel) => T;
  },
): T => {
  if (
    startFeedCardModel instanceof PersistedLinkStartFeedCardModel ||
    startFeedCardModel instanceof NewLinkStartFeedCardModel
  ) {
    return dispatcher[StartFeedCardTypeEnum.Link](startFeedCardModel);
  } else if (
    startFeedCardModel instanceof PersistedTemplateCategoryStartFeedCardModel ||
    startFeedCardModel instanceof NewTemplateCategoryStartFeedCardModel
  ) {
    return dispatcher[StartFeedCardTypeEnum.TemplateCategory](startFeedCardModel);
  }
  throw Error('Unrecognized type found in dispatchStartFeedCardModel');
};

export const dispatchNewStartFeedCardModel = <T extends any>(
  newStartFeedCardModel: NewStartFeedCardModel,
  dispatcher: {
    [StartFeedCardTypeEnum.Link]: (model: NewLinkStartFeedCardModel) => T;
    [StartFeedCardTypeEnum.TemplateCategory]: (model: NewTemplateCategoryStartFeedCardModel) => T;
  },
): T => {
  if (newStartFeedCardModel instanceof NewLinkStartFeedCardModel) {
    return dispatcher[StartFeedCardTypeEnum.Link](newStartFeedCardModel);
  } else if (newStartFeedCardModel instanceof NewTemplateCategoryStartFeedCardModel) {
    return dispatcher[StartFeedCardTypeEnum.TemplateCategory](newStartFeedCardModel);
  }
  throw Error('Unrecognized type found in dispatchNewStartFeedCardModel');
};

export const dispatchPersistedStartFeedCardModel = <T extends any>(
  persistedStartFeedCardModel: PersistedStartFeedCardModel,
  dispatcher: {
    [StartFeedCardTypeEnum.Link]: (model: PersistedLinkStartFeedCardModel) => T;
    [StartFeedCardTypeEnum.TemplateCategory]: (model: PersistedTemplateCategoryStartFeedCardModel) => T;
  },
): T => {
  if (persistedStartFeedCardModel instanceof PersistedLinkStartFeedCardModel) {
    return dispatcher[StartFeedCardTypeEnum.Link](persistedStartFeedCardModel);
  } else if (persistedStartFeedCardModel instanceof PersistedTemplateCategoryStartFeedCardModel) {
    return dispatcher[StartFeedCardTypeEnum.TemplateCategory](persistedStartFeedCardModel);
  }
  throw Error('Unrecognized type found in dispatchNewStartFeedCardModel');
};
