import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { StartFeedCardDto, parseStartFeedCardDto } from '../features/start-feed-cards/dtos/start-feed-card-dto';
import { PersistedStartFeedCardModel } from '../features/start-feed-cards/models/persisted-start-feed-card-model';
import { StartFeedCardCard } from '../features/start-feed-cards/components/start-feed-card-card';
import {
  PicButton,
  PicConfirmer,
  PicDivider,
  PicDropdown,
  PicIconName,
  PicNotifier,
  PicReorderItemsPanel,
  usePicBulkEditItems,
} from '@cardinalblue/pic-collage-cms-ui';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { ReorganizeDataController } from '../features/common/components/controllers/reorganize-data-controller';
import { ContentRenderer } from '../features/common/components/renderers/content-renderer';

type Props = {
  formAuthenticityToken: string;
  countryDtos: CountryDto[];
  startFeedCardDtos: StartFeedCardDto[];
  templateTagDtos: TemplateTagDto[];
};

const ListStartFeedCardsPageContent: React.FC<Props> = ({
  formAuthenticityToken,
  countryDtos,
  startFeedCardDtos,
  templateTagDtos,
}) => {
  const picNotifier = React.useMemo(() => new PicNotifier(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);

  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);

  const startFeedCardKeyGetter = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => startFeedCard.getId(),
    [],
  );
  const startFeedCardCloner = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => startFeedCard.clone(),
    [],
  );

  const sourceStartFeedCards = React.useMemo<PersistedStartFeedCardModel[]>(
    () => startFeedCardDtos.map((dto) => parseStartFeedCardDto(countries, dto)),
    [],
  );

  // Start feed cards that are currently live
  const liveStartFeedCards = React.useMemo(
    () => sourceStartFeedCards.filter((startFeedCard) => startFeedCard.isLive()),
    [sourceStartFeedCards],
  );

  const {
    draftItems: draftLiveStartFeedCards,
    selectedDraftItemKeys: selectedDraftLiveStartFeedCardKeys,
    selectDraftItems: selectDraftLiveStartFeedCards,
    reorderDraftItems: reorderDraftLiveStartFeedCards,
    resetAllDraftItems: resetAllDraftLiveStartFeedCards,
    deselectAllSelectedDraftItems: deselectAllSelectedDraftLiveStartFeedCards,
    moveSelectedDraftItemsToTop: moveSelectedDraftLiveStartFeedCardsToTop,
    moveSelectedDraftItemsToBottom: moveSelectedDraftLiveStartFeedCardsToBottom,
    updateSelectedDraftItems: updateSelectedDraftLiveStartFeedCards,
  } = usePicBulkEditItems({
    items: liveStartFeedCards,
    itemCloner: startFeedCardCloner,
    itemKeyGetter: startFeedCardKeyGetter,
  });

  // Start feed cards that will be live shorlty, for us also known as "draft"
  const preLiveStartFeedCards = React.useMemo(
    () => sourceStartFeedCards.filter((startFeedCard) => !startFeedCard.isLive()),
    [sourceStartFeedCards],
  );

  const handleLiveStartFeedCardsSave = React.useCallback(async () => {
    const draftLiveStartFeedCardsWithPinning = draftLiveStartFeedCards.filter((card) => {
      const originalCard = liveStartFeedCards.find((c) => c.getId() === card.getId());
      if (!originalCard) return false;

      return originalCard.getIsPinned() !== card.getIsPinned();
    });

    await startFeedCardApi.bulkUpdate(draftLiveStartFeedCardsWithPinning);

    const error = await startFeedCardApi.updateOrder(draftLiveStartFeedCards.map((card) => card.getId()));
    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return;
    }

    window.location.reload();
  }, [startFeedCardApi, liveStartFeedCards, draftLiveStartFeedCards]);

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);

  const [liveStartFeedReorganizeMode, setLiveStartFeedReorganizeMode] = React.useState<'edit' | 'read'>('read');

  const [collapsed, setCollapsed] = React.useState(false);

  const handleStartFeedCardInspect = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => {
      if (liveStartFeedReorganizeMode === 'edit') {
        window.open(`/admin/start_feed_cards/${startFeedCard.getId()}`);
      } else {
        window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}`;
      }
    },
    [liveStartFeedReorganizeMode],
  );

  const handleStartFeedCardEditClick = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => {
      if (liveStartFeedReorganizeMode === 'edit') {
        window.open(`/admin/start_feed_cards/${startFeedCard.getId()}/edit`);
      } else {
        window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}/edit`;
      }
    },
    [liveStartFeedReorganizeMode],
  );

  const handleStartFeedCardCloneClick = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => {
      if (liveStartFeedReorganizeMode === 'edit') {
        window.open(`/admin/start_feed_cards/${startFeedCard.getId()}/clone`);
      } else {
        window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}/clone`;
      }
    },
    [liveStartFeedReorganizeMode],
  );

  const handleStartFeedCardsDeleteClick = React.useCallback(
    async (startFeedCard: PersistedStartFeedCardModel) => {
      const [confirmed, close] = await picConfirmer.pop({
        title: 'Delete Start Feed Card',
        message: `Are you sure you want to delete start feed card "${startFeedCard.getTitle()}"?`,
      });
      if (!confirmed) {
        close();
        return;
      }

      const error = await startFeedCardApi.delete(formAuthenticityToken, startFeedCard.getId());
      if (error) {
        close();
        return;
      }
      window.location.reload();
    },
    [formAuthenticityToken, startFeedCardApi, picConfirmer],
  );

  const createStartFeedCardItemGroups = React.useMemo(
    () => [
      {
        title: null,
        items: [
          { label: 'Template Category', value: 'StartFeedTemplateCategoryCard' },
          { label: 'Link', value: 'StartFeedLinkCard' },
        ],
      },
    ],
    [],
  );
  const handleCreateStartFeedCardSelect = React.useCallback((option: { label: string; value: string }) => {
    window.location.href = `/admin/start_feed_cards/new?payload_type=${option.value}`;
  }, []);

  return (
    <div>
      <div style={{ marginBottom: '12px', display: 'flex', justifyContent: 'space-between' }}>
        <PicButton
          type="primary"
          outlined={false}
          copy="Toggle Collapse"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        />
        <div style={{ width: '300px' }}>
          <PicDropdown
            value={null}
            placeholder="Create Card"
            itemGroups={createStartFeedCardItemGroups}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value}
            onSelect={handleCreateStartFeedCardSelect}
          />
        </div>
      </div>
      <div style={{ marginBottom: '48px', display: 'flex', flexFlow: 'column', gap: '20px' }}>
        <ReorganizeDataController
          mode={liveStartFeedReorganizeMode}
          title="Live Start Feed Cards"
          itemName={{ singular: 'live start feed card', plural: 'live start feed cards' }}
          onCancelConfirm={() => {
            resetAllDraftLiveStartFeedCards();
            deselectAllSelectedDraftLiveStartFeedCards();
            setLiveStartFeedReorganizeMode('read');
          }}
          onEditClick={() => {
            setLiveStartFeedReorganizeMode('edit');
          }}
          onSaveConfirm={handleLiveStartFeedCardsSave}
          panel={
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
              <PicButton type="primary" copy="Deselect" onClick={deselectAllSelectedDraftLiveStartFeedCards} />
              <PicButton type="primary" iconName={PicIconName.Up} onClick={moveSelectedDraftLiveStartFeedCardsToTop} />
              <PicButton
                type="primary"
                iconName={PicIconName.Down}
                onClick={moveSelectedDraftLiveStartFeedCardsToBottom}
              />
              <PicButton
                type="primary"
                copy="Show/Hide"
                onClick={() => {
                  updateSelectedDraftLiveStartFeedCards((previousDraftLiveStartFeedCards) => {
                    const hasAnyPinnedCards = previousDraftLiveStartFeedCards.some((card) => card.getIsPinned());

                    previousDraftLiveStartFeedCards.forEach((card) => {
                      card.updateIsPinned(!hasAnyPinnedCards);
                    });

                    return previousDraftLiveStartFeedCards;
                  });
                }}
              />
            </div>
          }
        />

        <ContentRenderer loading={false} noContent={draftLiveStartFeedCards.length === 0}>
          <PicReorderItemsPanel
            items={draftLiveStartFeedCards}
            column={1}
            selectedItemKeys={selectedDraftLiveStartFeedCardKeys}
            onSelectedItemKeysChange={selectDraftLiveStartFeedCards}
            onItemsReorder={(items) => {
              reorderDraftLiveStartFeedCards(items.map(startFeedCardKeyGetter));
            }}
            disableSelection={liveStartFeedReorganizeMode === 'read'}
            disableDragging={liveStartFeedReorganizeMode === 'read'}
            itemKeyGetter={startFeedCardKeyGetter}
            itemRenderer={({ item, itemIndex, selected, onSelect }) => (
              <StartFeedCardCard
                order={itemIndex + 1}
                collapsed={collapsed}
                active={selected}
                startFeedCard={item}
                templateTags={templateTags}
                onClick={liveStartFeedReorganizeMode === 'edit' ? onSelect : undefined}
                onInspectClick={() => {
                  handleStartFeedCardInspect(item);
                }}
                onEditClick={() => {
                  handleStartFeedCardEditClick(item);
                }}
                onCloneClick={() => {
                  handleStartFeedCardCloneClick(item);
                }}
                onDeleteClick={
                  liveStartFeedReorganizeMode === 'read'
                    ? () => {
                        handleStartFeedCardsDeleteClick(item);
                      }
                    : undefined
                }
              />
            )}
          />
        </ContentRenderer>
      </div>
      <ActionableHeader title="Draft Start Feed Cards" />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <ContentRenderer loading={false} noContent={preLiveStartFeedCards.length === 0}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '8px',
          }}
        >
          {preLiveStartFeedCards.map((startFeedCard, startFeedCardIndex) => (
            <StartFeedCardCard
              order={startFeedCardIndex + 1}
              collapsed={collapsed}
              active={false}
              startFeedCard={startFeedCard}
              templateTags={templateTags}
              hidePinning
              onInspectClick={() => {
                handleStartFeedCardInspect(startFeedCard);
              }}
              onEditClick={() => {
                handleStartFeedCardEditClick(startFeedCard);
              }}
              onCloneClick={() => {
                handleStartFeedCardCloneClick(startFeedCard);
              }}
              onDeleteClick={() => {
                handleStartFeedCardsDeleteClick(startFeedCard);
              }}
            />
          ))}
        </div>
      </ContentRenderer>
    </div>
  );
};

const ListStartFeedCardsPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <ListStartFeedCardsPageContent {...props} />
    </CommonContextProvider>
  );
};

export default ListStartFeedCardsPage;
