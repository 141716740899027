import { AppVersionVo } from '../../../common/models/app-version-vo';
import { DateVo } from '../../../common/models/date-vo';
import { IPersistedStartFeedCardModel } from '../interfaces';
import { BaseStartFeedCardParams } from '../start-feed-card-model';
import { StartFeedCardTypeEnum } from '../start-feed-card-type-enum';
import { NewTemplateCategoryStartFeedCardModel } from './new-template-category-start-feed-card-model';
import { NewTemplateCategoryStartFeedCardChipModel } from './template-category-start-feed-card-chips/new-template-category-start-feed-card-chip-model';
import { PersistedTemplateCategoryStartFeedCardChipModel } from './template-category-start-feed-card-chips/persisted-template-category-start-feed-card-chip-model';
import { TemplateCategoryStartFeedCardChipModel } from './template-category-start-feed-card-chips/template-category-start-feed-card-chip-model';
import { TemplateCategoryStartFeedCardModel } from './template-category-start-feed-card-model';

export class PersistedTemplateCategoryStartFeedCardModel
  extends TemplateCategoryStartFeedCardModel
  implements IPersistedStartFeedCardModel
{
  constructor(
    params: BaseStartFeedCardParams,
    protected id: number,
    protected paylodId: number,
    protected chips: TemplateCategoryStartFeedCardChipModel[],
    protected chipsToRemove: PersistedTemplateCategoryStartFeedCardChipModel[],
  ) {
    super(params);
  }

  static create(
    id: number,
    analyticId: string,
    title: string,
    subtitle: string,
    label: string,
    startAt: DateVo | null,
    endAt: DateVo | null,
    isPinned: boolean,
    isCountriesExclusive: boolean,
    countries: string[],
    isTargetFreeUser: boolean,
    isTargetVipUser: boolean,
    isTargetNewUser: boolean,
    isTargetOldUser: boolean,
    targetMinimalRequiredIosAppVersion: AppVersionVo,
    targetMinimalRequiredAndroidAppVersion: AppVersionVo,
    payloadType: StartFeedCardTypeEnum,
    paylodId: number,
    chips: TemplateCategoryStartFeedCardChipModel[],
  ) {
    return new PersistedTemplateCategoryStartFeedCardModel(
      {
        analyticId,
        title,
        subtitle,
        label,
        startAt,
        endAt,
        isPinned,
        isCountriesExclusive,
        countries,
        isTargetFreeUser,
        isTargetVipUser,
        isTargetNewUser,
        isTargetOldUser,
        targetMinimalRequiredIosAppVersion,
        targetMinimalRequiredAndroidAppVersion,
        payloadType,
      },
      id,
      paylodId,
      chips,
      [],
    );
  }

  public clone(): PersistedTemplateCategoryStartFeedCardModel {
    return new PersistedTemplateCategoryStartFeedCardModel(
      this.generateBaseStartFeedCardParams(),
      this.id,
      this.paylodId,
      this.chips.map((c) => c.clone()),
      this.chipsToRemove.map((c) => c.clone()),
    );
  }

  public toNewStartFeedCard(): NewTemplateCategoryStartFeedCardModel {
    const newModel = NewTemplateCategoryStartFeedCardModel.create(
      this.generateBaseStartFeedCardParams(),
      this.chips.map((chip) =>
        NewTemplateCategoryStartFeedCardChipModel.create(chip.getName(), chip.getTemplateTagId()),
      ),
    );
    newModel.updateAnalyticId('');
    return newModel;
  }

  public isComplete(): boolean {
    if (!this.analyticId || !this.title) return false;
    if (this.chips.length === 0) return false;
    return this.chips.every((chip) => chip.isComplete(this.chips.length > 1));
  }

  public getId(): number {
    return this.id;
  }

  public getPayloadId(): number {
    return this.paylodId;
  }

  public getChips(): TemplateCategoryStartFeedCardChipModel[] {
    return this.chips;
  }

  public getChipsToRemove(): PersistedTemplateCategoryStartFeedCardChipModel[] {
    return this.chipsToRemove;
  }

  public addChip(chip: TemplateCategoryStartFeedCardChipModel): void {
    this.chips.push(chip);
  }

  public removeChip(chipIndex: number): void {
    const chipAtIndex = this.chips.at(chipIndex);
    if (!chipAtIndex) return;

    this.chips = this.chips.filter((_, index) => index !== chipIndex);
    // If it's an old chip, put it to destroy list
    if (chipAtIndex instanceof PersistedTemplateCategoryStartFeedCardChipModel) {
      this.chipsToRemove.push(chipAtIndex);
    }
  }

  public updateChip(chipIndex: number, newChip: TemplateCategoryStartFeedCardChipModel): void {
    this.chips = this.chips.map((chip, index) => (index !== chipIndex ? chip : newChip));
  }

  public updateChips(chips: TemplateCategoryStartFeedCardChipModel[]): void {
    this.chips = chips;
  }
}
